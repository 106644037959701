$(function () {
	var $navBar = jQuery('.navbar'),
		// $toggles = $navBar.find('.navbar-toggle'),
		$collapsibles = $navBar.find('.navbar-collapse');

	$collapsibles.on('show.bs.collapse', function () {
		// var $this = $(this);
		$collapsibles.collapse('hide');
	});
});
