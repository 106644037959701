var _ = require('underscore');

var $sidebar = $('#photographers-sidebar'),
	$nav = $sidebar.find('.nav'),
	$content = $('#photographer-content'),
	$inner = $content.find('.inner-content'),
	$spinner = $('<div class="spinner" />').append('<i class="fa" />').hide(),
	photographerTemplate = $('#photographer-template').html(),
	listTemplate = $('#photographers-list-template').html(),
	initialContentHeight = $content.height();

var Photographers = {
	injectPhotographers: function () {
		var promise = $.get('/api/photographers?max=9999'),
			$sidebarSpinner = $spinner.clone(true, true);

		$nav.hide();
		$sidebar.append($sidebarSpinner);
		$sidebarSpinner.trigger('show', 200);

		promise
			.done(function (response) {
				var data = response.data,
					compiledList = _.template(listTemplate);
				
		
				var sorted = _.sortBy(data, 'last_name'),
					grouped = _.groupBy(sorted, function(p) {			
						return p.last_name.charAt(0);
					}),
					$accordion = $(compiledList({data: grouped})).hide();

				$accordion
					.appendTo($sidebar)
					.slideDown(function () {
						$sidebarSpinner.trigger('hide');
					});

			})
			.fail(function (err) {
				$nav.show();
			});
	},
	bindUIEvents: function () {
		$spinner
			.on({
				'show': function(height) {
					$(this)
						.css('min-height', height)
						.stop(true, true)
						.fadeIn();
				},
				'hide': function () {
					$(this)
						.stop(true, true)
						.fadeOut();
				}
			});

		// when clicked, change the content on the right
		// to clicked photographer's info
		$sidebar.on('click', '[data-remote]', function (e) {
			var $this = $(this),
				promise;

			e.preventDefault();

			// mostly used for small screens, when clicked show the #photographer content
			window.location.hash = "#";
			window.location.hash = "#photographer-content";

			$inner.slideUp();

			$spinner.trigger('show', initialContentHeight);

			promise = $.get($this.data('remote'));
			promise
				.done(function (data) {
					var compiled = _.template(photographerTemplate);

					$inner.hide().html(compiled(data)).stop(true, true).slideDown(function () {
						$spinner.trigger('hide');
					});

				});
		});
	},
	init: function () {
		// append hidden $spinner to the main content's area
		$content.append($spinner);

		this.bindUIEvents();
		this.injectPhotographers();

	}
};

if ($sidebar.length) {
	Photographers.init();
}