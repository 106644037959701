var Utils = require('./_utils');
var itemDetailSelector = '#item-detail';
var ItemDetail = require('./_item-detail');

var spinnerIconClass = 'fa fa-spinner fa-spin fa-fw';
var $spinner = $('<i class="loading-spinner ' + spinnerIconClass + ' fa-2x"></i>');

function reloadWithImage(newUrl) {
    var $itemDetail = $(itemDetailSelector);
    var $modal = $itemDetail.closest('.modal');
    var $modalTitle = $modal.find('.modal-title');

    $itemDetail.addClass('loading').find('.item-image-inner').append($spinner);
    // debugger;
    $.get(newUrl, function(data) {
        var $data = $(data);
        var newData = $data.filter(itemDetailSelector).html();
        var newTitle = $data.find('#hidden-modal-title').text();

        $modal.trigger('hide.bs.modal');
        $modal.trigger('show.bs.modal');
        $itemDetail.find('.loading-spinner').remove();
        $itemDetail.removeClass('loading').html(newData);
        $modalTitle.text(newTitle);

        $modal.trigger('loaded.bs.modal');
        $modal.modal('handleUpdate');
        $modal.addClass($modal.data('new-modal-class'));
    }).fail(function(error) {
        $modal.trigger('hide.bs.modal');
        $modal.trigger('show.bs.modal');
        $itemDetail.find('.loading-spinner').remove();
        $itemDetail.removeClass('loading');
        $modal.trigger('loaded.bs.modal');
        $modal.modal('handleUpdate');
        $modal.addClass($modal.data('new-modal-class'));
        console.log(error);
    });
}

function bindUIActions () {
    $('body').on('click', '.js-image-nav:not([disabled])', function (e) {
        var $clicked = e.target.tagName === 'A' ? $(e.target) : $(e.target).closest('a');

        if ($clicked.closest('.modal').length) {
            e.preventDefault();
            reloadWithImage($clicked.data('new-url'));
        }
    });
}

function loadPrevNextBtn() {

    var $section = $('#image-nav-btns');

    if (!$section.length) {
        return;
    }

    var url = $section.data('url-btns');
    if (!url) {
        return;
    }
    var iconSpinnerHtml = '<i style="text-shadow: none;" class="'+spinnerIconClass+'"></i>',
        iconPrev = $('<i class="fa fa-chevron-left"></i>'),
        iconNext = $('<i class="fa fa-chevron-right"></i>');

    var prev = $section.find('.prev-image');
    var next = $section.find('.next-image');

    prev.html(iconSpinnerHtml);
    next.html(iconSpinnerHtml);

    $.get(url, function(data) {
        if (data.prev) {
            prev.attr("href", '/images/'+data.prev.id+'/'+data.prev.slug);
            prev.attr("data-new-url", '/images/'+data.prev.id+'/'+data.prev.slug+'/partial');
            prev.removeAttr('disabled');
        }

        if (data.next) {
            next.attr("href", '/images/'+data.next.id+'/'+data.next.slug);
            next.attr("data-new-url", '/images/'+data.next.id+'/'+data.next.slug+'/partial');
            next.removeAttr('disabled');
        }
    }).fail(function(error) {
        console.error(error);
    }).always(function() {
        prev.html(iconPrev);
        next.html(iconNext);
    });
}

function init () {
    bindUIActions();
    loadPrevNextBtn();
}

exports.init = init;
exports.loadPrevNextBtn = loadPrevNextBtn;
