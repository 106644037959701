var $check,
	$shipping,
	$billing;

function copyStateClasses () {
	var stateClass = $shipping.find('.js-state').attr('class'),
		stateInputClass = $shipping.find('.js-state-input').attr('class');

	var $billSelect = $billing.find('.js-state'),
		$billInput = $billing.find('.js-state-input');

	$billSelect.attr('class', stateClass);
	$billInput.attr('class', stateInputClass);
	$billSelect.find('select').prop('disabled', false);
	$billInput.find('input').prop('disabled', false);
}

function copyStateStatuses () {
	var stateStatus = $shipping.find('.js-state select').prop('disabled'),
		stateInputStatus = $shipping.find('.js-state-input input').prop('disabled');

	$billing.find('.js-state select').prop('disabled', stateStatus);
	$billing.find('.js-state-input input').prop('disabled', stateInputStatus);
}

function copyAddress () {
	$shipping.find('[data-same]:not(:disabled)').each(function () {
		var $this = $(this),
			value = $this.val(),
			field = $this.data('same');

		$billing.find('[data-same="' + field + '"]:not(:disabled)').val(value);
	});
}

function removeAddress () {
	$billing.find('[data-same]').each(function () {
		var $this = $(this);

		$this.val('');

		if ($this.find(':selected').length) {
			$this.find(':selected').removeAttr('selected');
		}
	});	
}

function bindUIActions () {
	$check.on('change', function (e) {
		if ($(this).is(':checked')) {
			copyStateClasses();
			copyAddress();
			copyStateStatuses();
		} else {
			removeAddress();
		}
	});
}

function init () {
	 $check = $('.js-same-checkbox');
	 $shipping = $('.js-shipping-address');
	 $billing = $('.js-billing-address');

	 bindUIActions();
}

exports.init = init;