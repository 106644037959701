var _ = require('underscore');

var $body,
	formSelector,
	$miniTotal,
	$floatingCheckout,
	$footerWrapper;

function updateMiniCart () {
	var value = parseInt($miniTotal.first().text());
	$miniTotal.text(value + 1);
}

function getOptionPrice (imageId, optionName, optionId) {
	var item = _.findWhere(PageData.images, { id: parseInt(imageId) }),
		option = _.findWhere(item[optionName], { id: parseInt(optionId) });

	return parseFloat(option.price);
}

function to2Decimals (num) {
	return parseFloat(Math.round(num * 100) / 100).toFixed(2);
}

function changePrice (e) {
	var $this = $(e.target),
		$form = $this.closest('form'),
		imageId = $form.data('image-id'),
		$selects = $form.find('select'),
		$sizeSelect = $selects.filter('[data-name="print_sizes"]'),
		$typeSelect = $selects.filter('[data-name="print_types"]'),
		$price = $form.find('.js-item-price'),
		size = $sizeSelect.val(),
		type = $typeSelect.val();

	// calculate each price
	var initialPrice = parseFloat($price.data('initial-price')),
		sizePrice = size ? getOptionPrice(imageId, $sizeSelect.data('name'), size) : 0,
		typePrice = type ? getOptionPrice(imageId, $typeSelect.data('name'), type) : 0;

	$price.text(to2Decimals(initialPrice + sizePrice + typePrice));
}

function onScroll () {
	var scrollTop = $(window).scrollTop();

	if (
		scrollTop > 100 &&
		scrollTop < $(document).height() - $(window).height() - $footerWrapper.height() - 90
	) {
		$floatingCheckout.addClass('is-floating');
	} else {
		$floatingCheckout.removeClass('is-floating');
	}
}

function bindUIActions () {
	// when item is added to cart, update mini cart
	$body.on('injectNewData', formSelector, updateMiniCart);

	$body.on('change', formSelector + ' select', changePrice);

	$(document).on('scroll', onScroll);
}

function init () {
	$body = $('body');
	formSelector = '.js-add-to-cart-form';
	$miniTotal = $('.js-mini-cart-total');
	$floatingCheckout = $('#floating-checkout');
	$footerWrapper = $('#footer').parent();

	bindUIActions();
}

exports.init = init;
