'use strict';

/**
 * Default Esensi modal
 */

// Require vendor dependencies
// window.$ = window.jQuery = require('jquery');

// Require custom dependencies
var Esensi = require('../common/_esensi'),
    Utils = require('./_utils');

// Add modal to the Esensi namespace
Esensi.modal = {};
Esensi.modal.context = $('#esensiModal');

Esensi.modal.addCustomClass = function (e) {
    var $clicked = $(e.relatedTarget);
    var $modal = $(e.target);
    var newModalClass = $clicked.data('modal-class') || '';

    if (newModalClass) {

        // the clicked element is only present for the 'show.bs.modal' event
        $modal
            .data('new-modal-class', newModalClass)
            .data('extra-class', newModalClass)
            .addClass(newModalClass);
    }
};
Esensi.modal.removeCustomClass = function (e) {
    var $modal = $(e.target);
    var newModalClass = $modal.data('extra-class');

    // the clicked element is only present for the 'show.bs.modal' event
    $modal
        .data('extra-class', '')
        .removeClass(newModalClass);
};

$(function() {
    var $context = Esensi.modal.context;
    // add custom class to the modal if defined as "data-modal-class"
    $context
        .on('show.bs.modal', Esensi.modal.addCustomClass)

    // Fix for modal reloads
    // http://stackoverflow.com/questions/12286332/
        .on('hidden.bs.modal', function (e) {
            $(this).removeData('bs.modal').find('.modal-content').empty();
             Esensi.modal.removeCustomClass(e);
        })

        .on('click', '[data-modal-submit="button"]', function (e) {
            e.preventDefault();
            $(this).closest('.modal').find('form').submit();
        })

        // links that close modal and then goes to its href
        .on('click', '.js-close-modal', function (e) {
            var $modal = $(e.delegateTarget),
                link = $(e.target).attr('href') || $(e.target).closest('a').attr('href');

            $modal
                .modal('hide')
                .on('hidden.bs.modal', function () {
                    window.location.href = link;
                });

            e.preventDefault();
        });
});
