// behavior for item-detail lightbox

var Utils = require('./_utils'),
    ToggleBox = require('./_toggle-box');

var ctaContainer = '#item-info-cta',
    formContainers = '.image-form-container',
    itemDetail = '#item-detail',
    specs = '.item-info-specs',
    counter,
    printButton;

var printPage = function () {
        window.print();
    },
    changeInfoStructure = function () {
        var $formContainers = $(formContainers),
            $specsClone = $(specs).clone().addClass('clone'),
            $itemDetail = $(itemDetail);

        // hide share-image form and re-position it below buttons
        $formContainers.insertAfter($(ctaContainer));
        $itemDetail.append($specsClone);
    },
    initItemDetail = function () {
        var _this = this;

        printButton = '#print-button';

        changeInfoStructure();
        ToggleBox.init();

        // print button
        Utils.$body.on('click', printButton, function () {
            printPage();
        });

        Utils.$modal
            .on('show.bs.modal', function () {
                counter = 0;
            })
            .on('hide.bs.modal', function () {
                // setting counter to 0 for next modal
                counter = 0;
            })
            .on('loaded.bs.modal', function (e) {
                // using this counter as "loaded.bs.modal"
                // is buggye: is being triggered multiple times
                // debugger;
                if (!counter) {
                    changeInfoStructure();
                }
                counter++;
            });
    };

module.exports.init = initItemDetail;
