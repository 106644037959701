require('selectize');

var $modal = $('#esensiModal'),
	selector = '.js-custom-select';

function bindUIActions () {
	$modal
		.on('loaded.bs.modal', function () {
			if (!$modal.hasClass('modal-loaded')) {
				startPlugin($(this).find(selector));
				$modal.trigger('selectized.bs.modal');
				$modal.addClass('modal-loaded')
			}
		})
		.on('hide.bs.modal', function () {
			$modal.removeClass('modal-loaded');
		});
}

function startPlugin ($selector) {	
	$selector.selectize();
}

function init () {
	startPlugin($(selector));

	bindUIActions();
}

exports.init = init;