var $removeBtns,
	$hiddenInput;

function bindUIActions () {
	$removeBtns.on('click', function(e) {
		$hiddenInput.val($(this).data('item-id'))

		$hiddenInput.closest('form').submit();

		e.stopPropagation();
		e.preventDefault();
	});
}

function init () {
	$removeBtns = $('.js-remove-item');
	$hiddenInput = $('#hidden-delete-id');

	bindUIActions();
}

exports.init = init;