var $linkBackTop;

function bindUIActions () {
    $(document).scroll(function() {
        var y = $(this).scrollTop();

        if (y > 200) {
            $linkBackTop.fadeIn();
        } else {
            $linkBackTop.fadeOut();
        }
    });
}

function init () {
	$linkBackTop = $('#link-back-top');

	bindUIActions();
}

exports.init = init;