var validate = require('jquery-validation'),
	AjaxButtons = require('./_ajax-buttons'),
	ToggleBox = require('./_toggle-box'),
	Utils = require('./_utils');

// forms selectors
var shareForm = '#share',
	printForm = '#print-order',
	requestForm = '#request-license',
	researchRequestForm = '#research-request-form',
	$fullNameForm = $('#account-fullname-form'),
	$emailForm= $('#account-email-form'),
	$passwordForm = $('#account-password-form'),
	$newAddressForm = $('#account-newaddress-form'),
	$newPhoneForm = $('#account-newphone-form'),
	$registerForm = $('#register-form'),
	addToCartForms = '.js-add-to-cart-form',
	timeToHidemessage = 3000;



// field selectors for data to replace in Account page after it's saved
var fieldSelectors = {
	'$email': $('#email-data-field'),
	'$firstName': $('#first-name-data-field'),
	'$lastName': $('#last-name-data-field'),
	'$company': $('#company-data-field')
};

// injects new saved/returned data in the dom
var injectNewData = function (e, data) {
	var email = data.email,
		firstName = data.name.first_name,
		lastName = data.name.last_name,
		company = data.user_profile ? data.user_profile.company : '';

	if (email) {
		fieldSelectors.$email.text(email);
	}
	if (firstName) {
		fieldSelectors.$firstName.text(firstName);
	}
	if (lastName) {
		fieldSelectors.$lastName.text(lastName);
	}
	if (company) {
		fieldSelectors.$company.text(company);
	}
};

// injects server's feedback after form is submitted
var insertFormFeedback = function ($form, error) {
	var $feedback = $('<div />'),
		$button = $form.find('[type="submit"]').first();

	$feedback
		.html(error ? error.responseJSON.message : $form.data('success-text'))
		.addClass('form-message ' + (error ? 'bg-danger' : 'bg-success'))
		.hide();

	AjaxButtons.showCompleteMessage($button, !error);

	$button.before($feedback);
	$feedback.slideDown();

	if (!error) {
		$feedback
			.delay(timeToHidemessage)
			.slideUp(function () {
				$(this).remove();
			});
	}
};

var CommonForm = {
	errorClass: 'text-danger',
	errorElement: 'p',
	// how the error highlighting is handled
	highlight: function (element) {
		var $element = $(element),
			$formGroup = $element.closest('.form-group'),
			errorClasses = 'has-error has-feedback';

		if ($element.hasClass('selectized')) {
			$formGroup.find('.selectize-control')
					  .addClass(errorClasses);
		} else {
			$formGroup.addClass(errorClasses);
		}
	},
	unhighlight: function (element) {
		var $element = $(element),
			$formGroup = $element.closest('.form-group'),
			errorClasses = 'has-error has-feedback';

		if ($element.hasClass('selectized')) {
			$formGroup.find('.selectize-control')
					  .removeClass(errorClasses);
		} else {
			$formGroup.removeClass(errorClasses);
		}
	}
};

var closeFormBox = function ($form) {
	var $box = $form.closest('[data-toggle-box="target"]');

	if ($box.length) {
		setTimeout(function () {
			ToggleBox.toggleBox($box);
		}, timeToHidemessage + 300);
	}
};

// submit handler for ajax forms
var AjaxForm = {
	submitHandler: function (form) {
		var $form = $(form),
			url = $form.data('action') || $form.attr('action'),
			method = $form.data('method') || $form.attr('method'),
			$button = $form.find('[type="submit"]').first(),
			originalContent = $button.html();

		var promise = $.ajax({
			data: $form.serialize(),
			type: method,
			url: url,
		});

		// remove all previous form messages
		$form.find('.form-message').slideUp(function () {
			$(this).slideUp();
		});

		$button.data('originalContent', $button.html());

		AjaxButtons.insertLoadingMessage($button);

		promise
			.done(function (data) {
				insertFormFeedback($form);

				closeFormBox($form);
				$form.trigger('injectNewData', [data]);

                // close modal on success
                if ($form.data('close-modal-on-success') && $form.closest('.modal').length) {
                    window.setTimeout(function () {
                        $form.closest('.modal').modal('hide');
                    }, 600);
                }
			})
			.fail(function(error) {
				insertFormFeedback($form, error);
			});

		this.resetForm(form);
	}
};


// specific rules for the "share image" form
var ShareFormRules = {
	rules: {
		user_name: 'required',
		user_email: {
			required: true,
			email: true
		},
		recipients: {
			required: true,
			multiemail: true
		}
	}
};

// specific rules for the account email and password forms
var AccountFormRules = {
	email: {
		rules: {
			email_confirmation: {
				equalTo: "#email"
			}
		}
	},
	password: {
		rules: {
			password_confirmation: {
				equalTo: "#password"
			}
		}
	}
};

jQuery.validator.setDefaults({
	// fix for selectize boxes (https://gist.github.com/yannleretaille/8498983)
    ignore: ':hidden:not([class~=selectized]),:hidden > .selectized, .selectize-control .selectize-input input',
});

// add method to validate a list of email (not just one single email)
jQuery.validator.addMethod(
    "multiemail",
     function(value, element) {
         if (this.optional(element)) // return true on optional element
             return true;
         var emails = value.split(/[;,]+/); // split element by , and ;
         valid = true;
         for (var i in emails) {
             value = emails[i];
             valid = valid &&
                     jQuery.validator.methods.email.call(this, $.trim(value), element);
         }
         return valid;
     },

    jQuery.validator.messages.email
);


// validation for forms in the image detail page
// it is used also on 'loaded' in modals
function addValidationToImageForms () {
	$(shareForm).validate($.extend({}, CommonForm, AjaxForm, ShareFormRules));
	$(printForm).validate($.extend({}, CommonForm, AjaxForm));
	$(requestForm).validate($.extend({}, CommonForm, AjaxForm));
}

addValidationToImageForms();


// adds the injectNewData method only to forms that need it
$fullNameForm.on('injectNewData', injectNewData);
$emailForm.on('injectNewData', injectNewData);

// validations for forms in the Account page
$fullNameForm.validate($.extend({}, CommonForm, AjaxForm));
$emailForm.validate($.extend({}, CommonForm, AjaxForm, AccountFormRules.email));
$passwordForm.validate($.extend({}, CommonForm, AjaxForm, AccountFormRules.password));
$newAddressForm.validate($.extend({}, CommonForm));
$newPhoneForm.validate($.extend({}, CommonForm));
$registerForm.validate($.extend({}, CommonForm));

// other forms
$(researchRequestForm).validate($.extend({}, CommonForm, AjaxForm));
$(addToCartForms).each(function () {
	$(this).validate($.extend({}, CommonForm, AjaxForm));
});

// forms that are loaded in a modal
Utils.$modal.on('loaded.bs.modal', function (e) {
	addValidationToImageForms();
	$(researchRequestForm).validate($.extend({}, CommonForm));
	$(e.target).find(addToCartForms).validate($.extend({}, CommonForm, AjaxForm));
});
