var _ = require('underscore');

var Utils = require('./_utils');

var $frameSelect, $sizeSelect,
	$framable, frameValue;

function getOption (imageId, optionName, filters) {
	var item = _.findWhere(PageData.images, { id: parseInt(imageId) }),
		option = _.findWhere(item[optionName], filters);

	return option;
}

function getGroupSiblings (imageId, optionName, groupId) {
    var item = _.findWhere(PageData.images, { id: parseInt(imageId) }),
        siblings = _.where(item[optionName], { group: groupId });

    return siblings;
}

function getNewSize (isFramed) {
	var sizeId = parseInt($sizeSelect.val());
	var optionName = $sizeSelect.data('name');
	var imageId = $sizeSelect.closest('form').data('image-id');

	var filters = isNaN(sizeId) ? { framed: 1 } : { id: sizeId };

    var option = getOption(imageId, optionName, filters);

	if (option) {
		return getOption(imageId, optionName, { framed: isFramed, group: option.group });
	} else {
		return false;
	}
}

function changeSize (newSize) {
	// $sizeSelect.val(newSize.id);
	$sizeSelect[0].selectize.setValue(newSize.id, 'silent');
}

function toggleFrame (value) {
	if (value) {
		$framable.addClass('is-framed');
	} else {
		$framable.removeClass('is-framed');
	}
}

function onFrameChange (e) {
	var $this = $(this),
		value = parseInt($this.val()),
		newSize = getNewSize(value);

	if (newSize) {
		changeSize(newSize);
		toggleFrame(value);
		frameValue = $this.val();
	} else {
		$this.val(frameValue);
		return false;
	}
}

function onSizeChange (e) {
	var $this = $(this),
		sizeId = parseInt($this.val()),
		optionName = $this.data('name'),
		imageId = $this.closest('form').data('image-id');

	var option = getOption(imageId, optionName, { id: sizeId });

    if (option) {
        var siblings = getGroupSiblings(imageId, optionName, option.group);
        var groupFrameOptions = _.uniq(_.pluck(siblings, 'framed'));

        // hide all options and show only those available
        $frameSelect[0].selectize.removeOption(0);
        $frameSelect[0].selectize.removeOption(1);
        _.each(groupFrameOptions, function(value) {
            $frameSelect[0].selectize.addOption({ value: value, text: value ? 'Framed' : 'Unframed' });
        });
        $frameSelect[0].selectize.refreshOptions(false);

		// change framed val in frame dropdown
		$frameSelect[0].selectize.setValue(option.framed, 'silent');
		frameValue = option.framed;
		toggleFrame(option.framed);
	}
}

function bindUIActions () {
	$frameSelect.on('change', onFrameChange);
	$sizeSelect.on('change', onSizeChange);
}

function init () {
	$frameSelect = $('select.js-frame-select');
	$sizeSelect = $('select.js-size-select');
	$framable = $('.js-framable');

	bindUIActions();

	// trigger initial size select to change frame accordingly
	$sizeSelect.trigger('change');

	// save frame value select
	frameValue = $frameSelect.val();
}

exports.init = init;
