/*jshint node: true, jquery: true */

"use strict";

/**
 * Twitter Bootstrap Optimized build
 */

// Require dependencies
global.$ = global.jQuery = require('jquery');

// Enable only the things you need here.
require('bootstrap-transition');
require('bootstrap-alert');
// require('bootstrap-button');
require('bootstrap-carousel');
require('bootstrap-collapse');
require('bootstrap-dropdown');
require('bootstrap-modal');
require('bootstrap-tooltip');
// require('bootstrap-popover');
// require('bootstrap-scrollspy');
// require('bootstrap-tab');
// require('bootstrap-affix');
