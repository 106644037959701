var Utils = require('./_utils');

var AjaxButtons = {
	button:'[data-ajax-button="true"]',
	Default: {
		loading: 'Loading...',
		success: 'Complete!',
		fail: 'Try again...'
	},
	alreadyAddedText: '<li>Already added to all your lightboxes!</li>',

	// show intermediate and final message for an 'ajax button'
	showCompleteMessage: function ($target, success, finalMsg) {
		var self = this,
			successText = $target.data('success-text') || this.Default.success,
			failText = $target.data('fail-text') || this.Default.fail,
			statusMessage = success ? successText : failText,
			finalMessage = finalMsg || ($target.data('keep-success') ? successText : $target.data('originalContent'));

		$target.html(statusMessage);

		setTimeout(function () {
			var lightboxImageId = $target.data('lastLightboxImageId');

			$target.removeClass('disabled').html(finalMessage);

			if ($target.data('isInMenu')) {
				$target.next('.btn').removeClass('disabled');

				// if this is an 'add to lightbox' button, search for other buttons in the page
				if (lightboxImageId) {
					self.$targetsToChange.text(finalMessage);
				}
			}
		}, 3000);
	},
	// remove every 'add to lightbox' link in the page for lightbox just added
	removeAddedLink: function (lightboxId) {
		var _this = this,
			$links = Utils.$body.find('[data-lightbox-image-id="' + lightboxId + '"]');

		this.$targetsToChange = $links.closest('.btn-group').find('[data-toggle="dropdown"]').first();

		$links.each(function () {
			var $this = $(this),
				$menu = $this.closest('.dropdown-menu');

			// check if link is in a menu
			if ($menu.length) {
				$this.closest('li').remove();

				_this.addAlreadyAddedText($menu);
			}
		});
	},
	// when there are no more lightboxes to add
	addAlreadyAddedText: function ($menu) {

		if(!$menu.children('li:not(.js-add-new-lightbox-link):not(.js-divider)').length) {
			$menu.addClass('alert alert-success').prepend(this.alreadyAddedText);
		}
	},
	// insert loading message in holder button
	insertLoadingMessage: function ($target) {
		var message = $target.data('loading-text') || (this.Default ? this.Default.loading : '');

		$target.addClass('disabled').html(message);
	},
	// put the last added lightbox at the top of every menu
	// of the images not in the lightbox
	putLastLightboxTop: function (lightboxId) {
		var _this = this,
			$links = Utils.$body.find('[data-lightbox-id="' + lightboxId + '"]');

		// remove all the 'recently lightbox' of the page
		Utils.$body.find('.js-first-lightbox, .divider:not(.js-divider)').remove();

		$links.each(function () {
			var $this = $(this),
				$menu = $this.closest('.dropdown-menu'),
				$newCurrent;

			// check if link is in a menu
			if ($menu.length && $menu.children('li:not(.js-add-new-lightbox-link):not(.js-divider)').length > 1) {
				// clone the entire li
				$newCurrent = $this.parent().clone().addClass('js-first-lightbox');

				$menu.prepend('<li class="divider" />');
				$menu.prepend($newCurrent);
			} else {
				_this.addAlreadyAddedText($menu);
			}
		});
	},
	init: function () {
		// Binding the context of the methods
		for (var key in this) {
			if (typeof this[key] === 'function') {
				this[key] = this[key].bind(this);
			}
		}

		var _this = this;

		Utils.$body.on('click', this.button, function (e) {
			e.preventDefault();

			var $this = $(this),
				url = $this.attr('href'),
				// check if button is inside a menu
				isInMenu = !!$this.closest('.dropdown-menu').length,
				// if it is then make the menu's main button
				// the holder of the status' messages
				$menuButton = $this.closest('.btn-group').find('.btn').first(),
				$holderButton = isInMenu ? $menuButton : $this;

			// save original content to use later
			$holderButton
				.data('originalContent', $holderButton.html())
				.data('isInMenu', isInMenu);

			// insert loading message in button
			_this.insertLoadingMessage($holderButton);

			if (isInMenu) {
				$holderButton.next('.btn').addClass('disabled');
			}

			//hide every previous error message
			Utils.$body.find('.js-alert-too-many-images').remove();


// TODO: Correct repeated pattern done/fail
//		 probably with promise.always
			$.when($.get(url))
				.done(function () {
					_this.removeAddedLink($this.data('lightbox-image-id'));

					if ($this.data('lightbox-id')) {
						_this.putLastLightboxTop($this.data('lightbox-id'));
					}

					// save last lightbox-image id in the holder button
					$holderButton.data('lastLightboxImageId', $this.data('lightbox-image-id'));

					_this.showCompleteMessage($holderButton, true);
				})
				.fail(function (error) {
					if (error.responseJSON) {

						var $error = $('<div></div>', {
								'text': error.responseJSON.message,
								'class': 'alert-small alert-danger alert-dismissible js-alert-too-many-images'
							}),
							$button = $('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>');

						$error.prepend($button);

						$button.on('click', function () {
							$(this).parent().slideUp();
						});

						$holderButton.closest('.btn-group').parent().append($error);
					}

					_this.showCompleteMessage($holderButton, false, 'Add to Lightbox');
				});
		});

	}
};

AjaxButtons.init();

module.exports.showCompleteMessage = AjaxButtons.showCompleteMessage;
module.exports.insertLoadingMessage = AjaxButtons.insertLoadingMessage;
