/*
	.js-country
	.js-fieldset
	.js-state
	.js-province
	.js-state-input
	.js-state-container
*/

var $country;

function toggleStateSelectors (selectorName) {

	var $form = $country.closest('.js-fieldset'),
		$stateContainer = $form.find('.js-state-container');

	// hide and disable ALL selectors
	$stateContainer
		.children()
		.addClass('hidden')
		.find('input, select')
		.attr('disabled', true);

	// enable selector
	$stateContainer
		.find('.js-' + selectorName)
		.removeClass('hidden')
		.find('input, select')
		.attr('disabled', false);

	$stateContainer.find(':disabled').val('');
}

function changeCountry () {
	$country = $(this);

	switch ($country.val()) {
		case '224': toggleStateSelectors('state');
			break;
		case 'CA': toggleStateSelectors('state'); // change to province (and use .js-province) if CA provinces are in an different dropdown than US states
			break;
		default: toggleStateSelectors('state-input');
	}
}

function bindUIActions () {
	// listen to country changes
	$country.on('change', changeCountry);
}

function init () {
	$country = $('.js-country').find('select');

	bindUIActions();

	// check initial value of country
	$country.each(changeCountry);
}

exports.init = init;