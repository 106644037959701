'use strict';

/**
 * Global application start
 */

// Require custom dependencies
require('./_csrf');
require('./_plugins');
require('./_esensi');

// Run Google Analytics
// require('./_ga');
