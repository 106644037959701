// Require vendor dependencies
window.$ = window.jQuery = require('jquery');

// Do something after DOM is ready
$(function () {
  let count = 1;
  let items = $('.top-bar span');

  setInterval(() => {
    if(count > items.length - 1) {
      count = 0;
    }
    items.hide();
    items.eq(count).fadeIn();
    count += 1
  }, 5000);

  const bar = $('#top-bar');
  if(window.sessionStorage.getItem('bar') !== 'bar') {
    //bar.show();
  }

  $('.close-thin').on('click', function() {
    //window.sessionStorage.setItem('bar', 'bar');
    var expires = (new Date(Date.now()+ 86400*2*1000)).toUTCString();
    document.cookie = "banner=active;expires=" + expires + ";path=/;";
    bar.fadeOut();
  });
});
