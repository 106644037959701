/*jshint node: true, jquery: true */

"use strict";

/**
 * Public application scripts
 */

// Enable JS if available
document.body.className += ' js';

// Require jQuery. (CommonJS compatible if installed with NPM.)
// You must use this syntax to prevent issues, especially w/ Bootstrap.
global.$ = global.jQuery = require('jquery');
global.imageNav = require('./public/_image-nav');

// Require AJAX transport for jQuery. (CommonJS compatible.)
require('jQuery-ajaxTransport-XDomainRequest');

// Require Bootstrap. (Uses browserify-shim.)
var bootstrap = require('./public/_bootstrap');
require('bootstrap-datepicker');
require('./common/_top-banner.js');

// Load polyfills
require('picturefill');

// Bootstrap the application
require('./common/_start');
require('./public/_modal');

// Custom code
require('./public/_navbar');
require('./public/_item-detail').init();
require('./public/_ajax-buttons');
require('./public/_forms-validation');
require('./public/_photographers');
require('./public/_tooltip');
require('./public/_login-button');

$(document).ready(function () {
	require('./public/_cart').init();
	require('./public/_checkout').init();
	require('./public/_shopping').init();
	require('./public/_stateSelector').init();
    require('./public/_custom-select').init();
		global.imageNav.init();
    require('./public/_link-back-top').init();
	// require('./public/_wrapItemsForPrint').init();
});

$('.modal').on('selectized.bs.modal', function (e) {
	require('./public/_frameSelect').init();
});

require('./public/_ganalytics');
