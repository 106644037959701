'use strict';

/**
 * Avoid `console` errors in browsers that lack a console.
 */
var method;
var noop = function () {};
var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeStamp', 'trace', 'warn'
];
var l = methods.length;
var c = (window.console = window.console || {});
while (l--) {
    method = methods[l];

    // Only stub undefined methods.
    if (!c[method]) {
        c[method] = noop;
    }
}

/**
 * Add a 'loaded' event for modal callbacks
 *
 * @see https://github.com/twbs/bootstrap/pull/6846#issuecomment-19829874
 */
var $ = require('jquery');
$.fn.jqueryLoad = $.fn.load;
$.fn.load = function(url, params, callback) {
    var $this = $(this);
    var cb = $.isFunction(params) ? params: callback || $.noop;
    var wrapped = function(responseText, textStatus, XMLHttpRequest) {
        cb(responseText, textStatus, XMLHttpRequest);
        $this.trigger('loaded');
    };

    if ($.isFunction(params)) {
        params = wrapped;
    } else {
        callback = wrapped;
    }

    $this.jqueryLoad(url, params, callback);

    return this;
};

/**
 * Adds better support for IE10 and Windows 8 Phone
 *
 * @see http://getbootstrap.com/getting-started/#support-ie10-width
 */
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style');
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    );
    document.querySelector('head').appendChild(msViewportStyle);
}
