// custom toggle box that opens inside lightboxes

var Utils = require('./_utils');

var ToggleBox = {
	slideDuration: 200,
	toggleTargets: '[data-toggle-box="target"]',
	toggleButton: '[data-toggle-box="button"]',
	closeButton: '[data-toggle-box="close"]',

	closeBox: function ($box) {
		$box.slideUp(this.slideDuration);
	},
	scrollToTarget: function (target) {
		var $target = $(target),
			isInModal = Utils.$modal.is(':visible'),
			$body = isInModal ? Utils.$modal.find('.modal-body') : Utils.$body,
			$scroller = isInModal ? Utils.$modal : Utils.$body,
			scrollTopTarget = isInModal ? $target.offset().top - $body.offset().top + 70 : $target.offset().top;

		$scroller.stop().animate({
		    'scrollTop': scrollTopTarget
		}, 600, function() {
			window.location.hash = target;
			window.location.hash = '#/';
		});
	},
	toggleBox: function (target) {
		$(this.toggleTargets).not($(target)).hide();
		$(target).stop(true, true).slideToggle(this.slideDuration);
	},
	init: function () {
		var self = this;

		$(self.toggleTargets).hide();

		Utils.$modal.on('loaded.bs.modal', function () {
			// hide all the toggle-targets inside modals
			$(self.toggleTargets).hide();
		});

		Utils.$body
			.on('click', self.toggleButton, function(e) {
				var target = $(this).attr('href');
				
				e.preventDefault();

				self.toggleBox(target);
				self.scrollToTarget(target);
			})

			// close button
			.on('click', self.closeButton, function() {
				self.closeBox($(this).closest(self.toggleTargets));
			});
	}
};

module.exports = ToggleBox;